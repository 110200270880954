/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Navbar from 'component/Navbar';
import { ReactComponent as CO2Icon } from 'assets/svg/CO2.svg';
import { ReactComponent as DrugsBoxIcon } from 'assets/svg/DrugsBox.svg';
import { ReactComponent as RecyclingLabelIcon } from 'assets/svg/RecyclingLabel.svg';
import { useHistory } from 'react-router-dom';
import BackgroundImage from 'assets/background.jpg';

function Home() {
    const theme = useTheme();
    const history = useHistory();
    const moduleButtonCss = css({
        cursor: 'pointer',
        boxShadow: '0px 0px 10px rgba(4, 12, 30, 0.08)',
        border: 'none',
        borderRadius: '2px',
        padding: '16px 40px',
        height: '144px',
        width: '440px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.white,
        marginBottom: '40px',
        '&:hover': {
            backgroundColor: theme.lightGrey
        },
        '.title': {
            color: theme.black,
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '22px',
            marginTop: '16px',
            marginBottom: '16px',
        },
        '.body': {
            marginTop: '0px',
            marginBottom: '0px',
            color: theme.darkGrey,
            fontSize: '14px',
            lineHeight: '17px',
        },
    });

    const handleModuleRedirection = (path: string) => {
        history.push(path);
    }

    return (
        <div
            css={{
                width: '100%',
                minHeight: '100vh',
                backgroundColor: theme.white,
                color: theme.black,
                display: 'flex',
            }}
        >
            <Navbar />
            <div
                css={{
                    flexGrow: 1,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'none',
                    padding: '98px 80px 80px',
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.3)) , url(${BackgroundImage})`,
                }}
            >
                <h2 css={{
                    marginTop: '0px',
                    marginBottom: '16px',
                    color: theme.white,
                    fontWeight: 600,
                    fontSize: '24px',
                    lineHeight: '28px',
                }}>For a sustainable access to health products</h2>

                <h5 css={{
                    marginTop: '0px',
                    marginBottom: '48px',
                    color: theme.white,
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 400,
                    maxWidth: '570px'
                }}>Ecovamed created a European origin label for medicines, as well as an innovative solution to determine the carbon footprint of active ingredients, and more generally the footprint of fine chemicals and specialty chemicals</h5>

                <button css={moduleButtonCss} onClick={() => handleModuleRedirection('/carbon_footprint/first_part/new')}>
                    <CO2Icon />
                    <p className="title">Carbon footprint</p>
                    <p className="body">Module to calculate the carbon footprint of chemical products</p>
                </button>

                <button css={moduleButtonCss} onClick={() => handleModuleRedirection('/drug_origin/new')}>
                    <DrugsBoxIcon />
                    <p className="title">Label of origin for a drug product</p>
                    <p className="body">Module to determine the European origin of your medecine</p>
                </button>

                <button css={moduleButtonCss} onClick={() => handleModuleRedirection('/drug_substance/new')}>
                    <RecyclingLabelIcon />
                    <p className="title">Label of origin for a drug substance</p>
                    <p className="body">Module to determine the European origin of your active pharmaceutical ingredient</p>
                </button>
            </div>
        </div>
    );
}

export default Home;