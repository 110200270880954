/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { ReactComponent as Eye } from 'assets/svg/Eye.svg';
import { ReactComponent as ClosedEye } from 'assets/svg/ClosedEye.svg';

type InputProps = {
    label: string;
    placeholder: string;
    name: string;
    className?: string;
    defaultValue?: string | number | null;
    disabled?: boolean;
    register?: any;
    registerOption?: any;
    type?: "text" | "password";
    error?: string;
    autoComplete?: string;
}

export default function Input({
    label,
    placeholder,
    name,
    disabled = false,
    className,
    defaultValue,
    register,
    registerOption = {},
    type = "text",
    error = "",
    autoComplete = ""
    }: InputProps
) {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    
    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                width: '100%',  
            }}
            className={className}
        >
            <label
                css={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontWeight: 500,
                    marginBottom: '8px',
                    color: disabled ? theme.grey : theme.black,
                }}
            >{label}</label>

            <div
                className="input__container"
                css={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <input
                    className="input__block"
                    css={{
                        border: '2px solid transparent',
                        borderRadius: '2px',
                        backgroundColor: theme.lightGrey,
                        padding: '16px',
                        paddingRight: type === "password" ? '48px' : '16px', 
                        fontSize: '16px',
                        height: '52px',
                        width: '100%',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: theme.black,
                        outline: 'none',
                        '::placeholder': {
                            color: theme.grey,
                        },
                        '&:hover, &:focus': {
                            borderColor: theme.primary,
                        },
                    }}
                    {...register(name, { ...registerOption })}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    type={type === "text" ? "text" : showPassword ? "text" : "password"}
                />
                {type === "password" && (
                    showPassword ? 
                    <button 
                        type="button"
                        onClick={handleShowPassword}
                        css={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '16px',
                            border: 'none',
                            background: 'transparent'
                        }}
                    >
                        <Eye />
                    </button> :
                    <button 
                        type="button"
                        onClick={handleShowPassword}
                        css={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '16px',
                            border: 'none',
                            background: 'transparent'
                        }}
                    >
                        <ClosedEye /> 
                    </button>
                )}
            </div>
           
            {error &&
                <p css={{
                    marginTop: '4px',
                    marginBottom: '0px',
                    color: theme.error,
                    fontSize: '12px',
                    lineHeight: '15px',
                }}>{error}</p>
            }
        </div>
    );
}