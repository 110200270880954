/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import _ from "lodash";
import Select from "component/Select";
import Input from "component/Input";
import {
  CAPEXOptions,
  CoolingWaterUnitOptions,
  EnergyUnitOptions,
  NitrogenUnitOptions,
  QuantityUnitOptions,
  RefrigerantGaseEnumOptions,
  ScopeUnitOptions,
  SteamUnitOptions,
} from "utils/enums";
import { InformationPoint } from "component/InformationPoint";
import { ReactComponent as AddIcon } from "assets/svg/Add.svg";
import TextArea from "component/TextArea";
import { decimalNumberRegex } from "utils/regex";
import { Plant } from "utils/plant";

function PlantForm({ isDisabled = false }: { isDisabled?: boolean }) {
  const theme = useTheme();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<Plant>();

  const { fields, append } = useFieldArray({
    control,
    name: "refrigerantGases",
  });

  const { fields: VOCfields, append: appendVOC } = useFieldArray({
    control,
    name: "volatileOrganicCompounds",
  });

  const appendGasLeakage = () => {
    append({ value: "", type: null });
  };

  const appendVolatileOrganicCompounds = () => {
    appendVOC({ name: "", quantity: '' });
  };

  return (
    <div>
      <p
        css={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17px",
          color: theme.black,
          marginTop: "40px",
          marginBottom: "24px",
          span: {
            fontWeight: 600,
          },
        }}
      >
        Annual <span>purchased</span> energy/utility by the plant:
      </p>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          label="Electricity"
          placeholder=""
          register={register}
          name="energyConsumptionElectricityValue"
          disabled={isDisabled}
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) ||
                "Electricity must be a number",
            },
          }}
          error={
            errors.energyConsumptionElectricityValue &&
            errors.energyConsumptionElectricityValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionElectricityUnit"
          defaultValue="MWh"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={EnergyUnitOptions}
              inputRef={ref}
              isDisabled={isDisabled}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={EnergyUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Natural gas"
          placeholder=""
          register={register}
          name="energyConsumptionNaturalGasValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) ||
                "Natural gas must be a number",
            },
          }}
          error={
            errors.energyConsumptionNaturalGasValue &&
            errors.energyConsumptionNaturalGasValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionNaturalGasUnit"
          defaultValue="MWh"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={EnergyUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={EnergyUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Coal"
          placeholder=""
          register={register}
          name="energyConsumptionCoalValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Coal must be a number",
            },
          }}
          error={
            errors.energyConsumptionCoalValue &&
            errors.energyConsumptionCoalValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionCoalUnit"
          defaultValue="MWh"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={EnergyUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={EnergyUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Fuel oil"
          placeholder=""
          register={register}
          name="energyConsumptionFuelOilValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Fuel oil must be a number",
            },
          }}
          error={
            errors.energyConsumptionFuelOilValue &&
            errors.energyConsumptionFuelOilValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionFuelOilUnit"
          defaultValue="Litres"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={QuantityUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={QuantityUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Gasoline"
          placeholder=""
          register={register}
          name="energyConsumptionGasolineValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Gasoline must be a number",
            },
          }}
          error={
            errors.energyConsumptionGasolineValue &&
            errors.energyConsumptionGasolineValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionGasolineUnit"
          defaultValue="Litres"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={QuantityUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={QuantityUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Steam"
          placeholder=""
          register={register}
          name="energyConsumptionSteamValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Steam must be a number",
            },
          }}
          error={
            errors.energyConsumptionSteamValue &&
            errors.energyConsumptionSteamValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionSteamUnit"
          defaultValue="Tons"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={SteamUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={SteamUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Heat"
          placeholder=""
          register={register}
          name="energyConsumptionHeatValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Heat must be a number",
            },
          }}
          error={
            errors.energyConsumptionHeatValue &&
            errors.energyConsumptionHeatValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionHeatUnit"
          defaultValue="MJ"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={EnergyUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={EnergyUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "40px" }}>
        <Input
          disabled={isDisabled}
          label="Liquid nitrogen"
          placeholder=""
          register={register}
          name="energyConsumptionLiquidNitrogenValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) ||
                "Liquid nitrogen must be a number",
            },
          }}
          error={
            errors.energyConsumptionLiquidNitrogenValue &&
            errors.energyConsumptionLiquidNitrogenValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyConsumptionLiquidNitrogenUnit"
          defaultValue="Tons"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={NitrogenUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={NitrogenUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Annual production volume of finished products"
          placeholder=""
          register={register}
          name="productionFPValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "The volume must be a number",
            },
          }}
          error={errors.productionFPValue && errors.productionFPValue.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />

        <Select
          isDisabled={true}
          options={QuantityUnitOptions}
          label="Unit"
          getOptionLabel={(option: any) => option.label}
          value={{ label: "Tons", value: "Tons" }}
          css={{
            maxWidth: "120px",
          }}
        />

        <InformationPoint
          css={{
            marginTop: "40px",
            marginLeft: "16px",
          }}
          text={`All products made in this plant, and not only the studied product`}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Annual production volume of intermediate and finished products"
          placeholder=""
          register={register}
          name="productionIntFPValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "The volume must be a number",
            },
          }}
          error={
            errors.productionIntFPValue && errors.productionIntFPValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
            label: {
              fontSize: "12px",
            },
          }}
        />

        <Select
          isDisabled={true}
          options={QuantityUnitOptions}
          label="Unit"
          getOptionLabel={(option: any) => option.label}
          value={{ label: "Tons", value: "Tons" }}
          css={{
            maxWidth: "120px",
          }}
        />

        <InformationPoint
          css={{
            marginTop: "40px",
            marginLeft: "16px",
          }}
          text={`If finished products are made in several steps, it includes the volume of intermediate products from all steps`}
        />
      </div>

      {fields.map((field, index) => (
        <div key={field.id} css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            disabled={isDisabled}
            label="Annual leakage/recharge of refrigerant gases (kg)"
            placeholder=""
            register={register}
            name={`refrigerantGases.${index}.value`}
            registerOption={{
              validate: {
                isNumber: (value: string) =>
                  decimalNumberRegex.test(value) ||
                  "Annual leakage/recharge must be a number",
              },
            }}
            error={_.get(errors, `refrigerantGases.${index}.value`)?.message}
            css={{
              maxWidth: "450px",
              marginRight: "16px",
            }}
          />
          <Controller
            control={control}
            name={`refrigerantGases.${index}.type`}
            defaultValue={null}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <Select
                isDisabled={isDisabled}
                onChange={(data: any) => onChange(data ? data.value : null)}
                options={RefrigerantGaseEnumOptions}
                inputRef={ref}
                label="Gas"
                getOptionLabel={(option: any) => option.label}
                value={RefrigerantGaseEnumOptions.find(
                  (el: any) => el.value === value
                )}
                error={error?.message}
                css={{
                  maxWidth: "200px",
                }}
              />
            )}
          />
        </div>
      ))}
      <button
        css={{
          cursor: "pointer",
          marginTop: "18px",
          marginBottom: "30px",
          background: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          padding: 0,
          color: theme.black,
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17px",
          width: "120px",
          svg: {
            marginRight: "12px",
          },
        }}
        type="button"
        onClick={appendGasLeakage}
      >
        <AddIcon />
        Add a line
      </button>

      {VOCfields.map((field, index) => (
        <div key={field.id} css={{ display: "flex", marginBottom: "16px" }}>
          <Input
            disabled={isDisabled}
            label="Volative Organic Compound (VOC)"
            placeholder="Name"
            register={register}
            name={`volatileOrganicCompounds.${index}.name`}
            error={_.get(errors, `volatileOrganicCompounds.${index}.name`)?.message}
            css={{
              maxWidth: "450px",
              marginRight: "16px",
            }}
          />
          <Input
            disabled={isDisabled}
            label="Tons / year"
            placeholder=""
            register={register}
            name={`volatileOrganicCompounds.${index}.quantity`}
            registerOption={{
              validate: {
                isNumber: (value: string) =>
                  decimalNumberRegex.test(value) ||
                  "Tons per year must be a number",
              },
            }}
            error={_.get(errors, `volatileOrganicCompounds.${index}.quantity`)?.message}
            css={{
              maxWidth: "200px",
            }}
          />

        </div>
      ))}
      <button
        css={{
          cursor: "pointer",
          marginTop: "18px",
          marginBottom: "30px",
          background: "transparent",
          border: "none",
          display: "flex",
          alignItems: "center",
          padding: 0,
          color: theme.black,
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17px",
          width: "120px",
          svg: {
            marginRight: "12px",
          },
        }}
        type="button"
        onClick={appendVolatileOrganicCompounds}
      >
        <AddIcon />
        Add a line
      </button>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Annual cooling water quantity"
          placeholder=""
          register={register}
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Cooling water must be a number",
            },
          }}
          name="coolingWaterValue"
          error={errors.coolingWaterValue && errors.coolingWaterValue.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="coolingWaterUnit"
          defaultValue="m3"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={CoolingWaterUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={CoolingWaterUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Annual CAPEX"
          placeholder=""
          register={register}
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Capex must be a number",
            },
          }}
          name="capexValue"
          error={errors.capexValue && errors.capexValue.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="capexUnit"
          defaultValue="Million €"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={CAPEXOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={CAPEXOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Annual production of energy by the plant"
          placeholder=""
          register={register}
          name="energyProductionValue"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) ||
                "Annual production of energy must be a number",
            },
          }}
          error={
            errors.energyProductionValue && errors.energyProductionValue.message
          }
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="energyProductionUnit"
          defaultValue="MWh"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={EnergyUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={EnergyUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Number of employees in the plant"
          placeholder=""
          register={register}
          name="employees"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) ||
                "Number of employees must be a number",
            },
          }}
          error={errors.employees && errors.employees.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "16px" }}>
        <Input
          disabled={isDisabled}
          label="Scope 1 CO2eq emissions"
          placeholder=""
          register={register}
          name="scope1Value"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Scope 1 must be a number",
            },
          }}
          error={errors.scope1Value && errors.scope1Value.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="scope1Unit"
          defaultValue="TonsCO2eq"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={ScopeUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={ScopeUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex", marginBottom: "40px" }}>
        <Input
          disabled={isDisabled}
          label="Scope 2 CO2eq emissions"
          placeholder=""
          register={register}
          name="scope2Value"
          registerOption={{
            validate: {
              isNumber: (value: string) =>
                decimalNumberRegex.test(value) || "Scope 2 must be a number",
            },
          }}
          error={errors.scope2Value && errors.scope2Value.message}
          css={{
            maxWidth: "450px",
            marginRight: "16px",
          }}
        />
        <Controller
          control={control}
          name="scope2Unit"
          defaultValue="TonsCO2eq"
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <Select
              isDisabled={isDisabled}
              onChange={(data: any) => onChange(data ? data.value : null)}
              options={ScopeUnitOptions}
              inputRef={ref}
              label="Unit"
              getOptionLabel={(option: any) => option.label}
              value={ScopeUnitOptions.find((el: any) => el.value === value)}
              error={error?.message}
              css={{
                maxWidth: "120px",
              }}
            />
          )}
        />
      </div>

      <div css={{ display: "flex" }}>
        <TextArea
          disabled={isDisabled}
          label="Comments"
          placeholder=""
          register={register}
          name="comments"
          error={errors.comments && errors.comments.message}
          css={{
            maxWidth: "450px",
          }}
        />
      </div>
    </div>
  );
}

export default PlantForm;
