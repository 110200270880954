/** @jsxImportSource @emotion/react */
import "./index.css";
import ReactDOM from "react-dom";
import { ThemeProvider as EmotionThemeProdiver } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";

declare module "@emotion/react" {
  export interface Theme {
    primary: string;
    secondary: string;
    black: string;
    darkGrey: string;
    grey: string;
    lightGrey: string;
    veryLightGrey: string;
    white: string;
    notCompleted: string;
    error: string;
    success: string;
  }
}

const emotionTheme = {
  primary: "#1A085C",
  secondary: "#43D869",
  black: "#040C1E",
  darkGrey: "#575757",
  grey: "#908F90",
  lightGrey: "#F6F5F6",
  veryLightGrey: "#FAFAFA",
  white: "#FFFFFF",
  notCompleted: "#E2BA2C",
  error: "#EC2300",
  success: "#4BB543",
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <EmotionThemeProdiver theme={emotionTheme}>
      <App />
    </EmotionThemeProdiver>
  </QueryClientProvider>,
  document.getElementById("root")
);
